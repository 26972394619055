

















































































import { format, subDays, subMonths } from "date-fns";

import Datum from "@/util/datum";
import Vue from "vue";
import { catVue } from "@/util/logging";

const fmtI = "yyyy-MM-dd";

export default Vue.extend({
  name: "DatumsbereichAuswahl",

  data() {
    return {
      menu2: false,
      bis: "", // Daten werden unten befüllt …
      bisFormatted: "",

      menu1: false,
      von: "",
      vonFormatted: "",
    };
  },

  computed: {
    // computedDateFormatted () {
    //   return cmp.datumDeutsch(this.date)
    // },
  },

  watch: {
    bis() {
      //         catVue.info("watch( bis ): " + this.bis);
      this.bisFormatted = Datum.datumDeutsch(this.bis);
      //         catVue.info("            : " + this.bisFormatted);
    },
    von() {
      //         catVue.info("watch( von ): " + this.von);
      this.vonFormatted = Datum.datumDeutsch(this.von);
      //         catVue.info("            : " + this.vonFormatted);
    },
  },

  created() {
    catVue.info(
      "created: DatumsbereichAuswahl auf " +
        this.$route.fullPath +
        " mit " +
        JSON.stringify(this.$route.query, undefined, 2)
    );
    catVue.info("von/bis = " + this.von + "/" + this.bis + " ... vor Query");

    let subtract = 7;
    catVue.info(
      "process.env.VUE_APP_BACKDATE := " + process.env.VUE_APP_BACKDATE
    );
    if (
      process.env.VUE_APP_BACKDATE !== undefined &&
      +process.env.VUE_APP_BACKDATE > 0
    ) {
      subtract = +process.env.VUE_APP_BACKDATE;
    }

    const start = subDays(new Date(), subtract);

    if (null !== this.$route.fullPath.match(/buch-bewegungen/)) {
      catVue.info("Monat");
      this.bis = format(start, fmtI);
      this.von = format(subMonths(start, 1), fmtI);
    } else if (null !== this.$route.fullPath.match(/kassencheck/)) {
      catVue.info("Woche");
      this.bis = format(subDays(start, -7), fmtI);
      this.von = format(start, fmtI);
    } else {
      catVue.info("Tag");
      this.bis = format(start, fmtI);
      this.von = this.bis;
    }

    if (this.$route.query["von"])
      this.von = Datum.datumISO(this.$route.query["von"] as string);

    if (this.$route.query["bis"])
      this.bis = Datum.datumISO(this.$route.query["bis"] as string);

    //       catVue.info(" Setting Dates …");
    this.bisFormatted = Datum.datumDeutsch(this.bis);
    this.vonFormatted = Datum.datumDeutsch(this.von);
    //       catVue.info(" Setting Dates … done.");

    // initial emit …..
    this.range();
  },

  methods: {
    range(): string {
      catVue.info("Range ... 1 ... " + this.von + "—" + this.bis);
      this.$router
        .replace({
          path: this.$route.path,
          query: { ...this.$route.query, von: this.von, bis: this.bis },
        })
        .catch(() => {
          /* intentionally left empty */
        });
      this.$emit("auswahl", { von: this.von, bis: this.bis });
      return "";
    },

    parseDate(date: string) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
});
